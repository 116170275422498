import React from "react";
import PropTypes from "prop-types";
import { Box } from "grommet";

const Section = ({ children, width, height, background, pad }) => (
  <Box
    align="center"
    background={background}
    pad={pad || { horizontal: "large", vertical: "medium" }}
  >
    <Box width={width} height={height} align="center">
      <Box>{children}</Box>
    </Box>
  </Box>
);

Section.propTypes = {
  ...Box.propTypes,
  children: PropTypes.node.isRequired,
  width: PropTypes.string
};

Section.defaultProps = {
  width: "xlarge",
  height: "unset"
};

export default Section;
