import React from "react"
import { Grommet, Box, Heading, Paragraph as GParagraph, Image } from "grommet"

import Section from "../components/framework/Section"
import SEO from "../components/seo"

const Paragraph = ({ children, ...props }) => (
  <Box {...props}>
    <GParagraph style={{ maxWidth: "100%" }}>{children}</GParagraph>
  </Box>
)

const CodeBox = ({ children }) => (
  <Box
    width="large"
    background="black"
    pad="small"
    round="xsmall"
    direction="row"
  >
    <span style={{ color: "#19be81", marginRight: "5px" }}>~ $ </span>{" "}
    {children}
  </Box>
)

export default function Page() {
  return (
    <Grommet>
      <SEO
        title="Walkthrough"
        keywords={[`bigdata`, `serverless`, `streams`]}
      />
      {/* <Section>
          <Box align="center">
            <Image src={logo} fit="contain" width="32px" />
          </Box>
        </Section> */}
      <Section width="large">
        <Heading>Building a Simple Furnace App</Heading>
        <Paragraph>
          We'll create an app to receive a webhook from a Support Ticket system
          when a ticket is created, take the ticket information, pass it through
          a series of operations including sentiment analysis and finally create
          a Slack message if there is negative sentiment.
        </Paragraph>
      </Section>
      <Section id="prereqs">
        <Heading level="3">First, some setup</Heading>
        <Paragraph>
          Ensure you have at least Node 10 installed on your computer and then
          install the Furnace CLI.
        </Paragraph>
        <CodeBox>npm install @project-furnace/cli -g</CodeBox>
      </Section>
      <Section id="ignite">
        <Heading level="3">Create your Furnace environment</Heading>
        <Paragraph>
          To get us up and running quickly we can skip installing the CI/CD
          components.
        </Paragraph>
        <CodeBox>furnace ignite --no-bootstrap</CodeBox>
      </Section>
      <Section id="new-stack">
        <Heading level="3">Initialize a new Furnace Stack</Heading>
        <Paragraph>
          A <strong>Stack</strong> is an application or a grouping of related
          components.
        </Paragraph>
        <CodeBox>furnace new demo-stack</CodeBox>
      </Section>
      <Section id="create-source">
        <Heading level="3">Create a new Source</Heading>
        <Paragraph>
          The entry point to a Furnace flow is a <strong>Source</strong>. It can
          be a <strong>Stream</strong>, <strong>Timer</strong> or{" "}
          <strong>Rest API</strong>.
        </Paragraph>
        <CodeBox>furnace create source new-ticket-hook --type restapi</CodeBox>
      </Section>
      <Section id="create-tap">
        <Heading level="3">Create a new Tap</Heading>
        <Paragraph>
          A <strong>Tap</strong> reads from the <strong>Source</strong>,
          translates the request and pushes data into a{" "}
          <strong>Pipeline</strong>.
        </Paragraph>
        <CodeBox>
          furnace create tap process-ticket-hook --source new-ticket-hook
        </CodeBox>
      </Section>
      <Section id="create-pipeline" width="large">
        <Heading level="3">Create a new Pipeline</Heading>
        <Paragraph>
          A <strong>Pipeline</strong> is a sequential flow of operations we call{" "}
          <strong>Functions</strong>.
        </Paragraph>
        <CodeBox>
          furnace create pipeline process-ticket --functions
          add-sentiment,core/slack-message
        </CodeBox>
        <Paragraph margin={{ top: "small" }}>
          We have two <strong>Functions</strong>, <strong>add-sentiment</strong>{" "}
          is created within the <strong>Stack</strong> and{" "}
          <strong>slack-message</strong> is referenced from the{" "}
          <strong>core</strong> repository, a library of pre-baked functions and
          components.
        </Paragraph>
      </Section>
      <Section id="deploy">
        <Heading level="3">Deploy the Stack</Heading>
        <Paragraph>
          Furnace allows you to deploy quickly to a sandbox environment whilst
          in development.
        </Paragraph>
        <CodeBox>furnace deploy sandbox</CodeBox>
      </Section>
      <Section id="deploy">&nbsp;</Section>
    </Grommet>
  )
}
